<template>
  <div class="hero-top-sos"
       :class="dark ? 'hero-top-sos__dark' : ''">
    <div class="hero-top-sos__wrapper">
      <slot/>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HeroTopSOS',
  props: {
    dark: {
      type: Boolean,
    },
  },
};
</script>
